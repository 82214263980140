var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"goodsList11"},[_c('div',{staticClass:"goodsList11_top"},[_c('div',{staticClass:"goodsList11_top_left"},[_c('img',{staticClass:"goodsList11_top_left_icon",attrs:{"src":`https://oss1.hk1686.com/img/036/zypa/2024/08/05/1722849732438`}})]),_vm._v(" "),_c('div',{staticClass:"goodsList11_top_left_gameList"},_vm._l((_vm.hotGamesArray),function(item,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(index < 8),expression:"index < 8"}],key:item.id,staticClass:"gameList_item",class:{ active: _vm.nowGameId == item.id },on:{"click":function($event){return _vm.switchGame(item.id)}}},[_c('div',{staticClass:"item_gameName"},[_vm._v(_vm._s(item.name))]),_vm._v(" "),(_vm.nowGameId == item.id)?_c('div',{staticClass:"item_border"}):_vm._e()])}),0),_vm._v(" "),_c('div',{staticClass:"goodsList11_top_right",on:{"click":function($event){return _vm.openUrl('/allgame')}}},[_c('div',{staticClass:"goodsList11_top_right_more"},[_vm._v("查看更多")]),_vm._v(" "),_c('img',{staticClass:"goodsList11_top_right_img",attrs:{"src":require("static/image/arrowicon/right-arrow2.png")}})])]),_vm._v(" "),_c('div',{staticClass:"goodsList11_bottom_box"},[_c('div',{staticClass:"goodsList11_bottom"},[_c('div',{staticClass:"goodsList11_bottom_content"},[(_vm.goodsListData.goodsList.length > 0)?_vm._l((_vm.goodsListData.goodsList),function(item,index){return _c('div',{key:item.id,staticClass:"content_item",class:{ secondRow: index > 3 },on:{"click":function($event){return _vm.goToGoodsDetail(item)}}},[_c('div',{staticClass:"item_imgBox"},[_c('newImg',{staticClass:"item_img",attrs:{"url_":item.thumbnail,"waterMark_":true}})],1),_vm._v(" "),_c('div',{staticClass:"item_info"},[_c('div',{staticClass:"item_title"},[(
                    item.accountTypeNames &&
                    item.accountTypeNames.includes('找回包赔')
                  )?_c('div',{staticClass:"item_info_baopei"},[_c('img',{attrs:{"src":"https://oss1.hk1686.com/img/036/zypa/2022/06/29/1656472900045","alt":""}}),_vm._v("找回包赔\n                ")]):_vm._e(),_vm._v(" "),_c('span',{staticClass:"item_info_titleText",style:({
                    textIndent:
                      item.accountTypeNames &&
                      item.accountTypeNames.includes('找回包赔')
                        ? '85px'
                        : '',
                  })},[_vm._v(_vm._s(item.bigTitle))])]),_vm._v(" "),_c('div',{staticStyle:{"display":"flex","flex-direction":"row","justify-content":"space-between","margin":"4px 0 4px 0"}},[_c('div',{staticClass:"item_areaServer"},[_c('div',{staticClass:"item_areaServer_text"},[_vm._v("\n                    "+_vm._s(`${item.areaName}-${item.serverName}`)+"\n                  ")])]),_vm._v(" "),_c('div',{staticClass:"item_bottom_time"},[_c('img',{staticClass:"time_clock",attrs:{"src":require("static/image/comm/lishi-qwrq.png"),"alt":""}}),_vm._v(" "),_c('div',{staticClass:"time_text"},[_vm._v("\n                    "+_vm._s(String(item.createTime).includes(":")
                        ? _vm.timeFormatUser(item.createTime, "YYYY-MM-DD")
                        : "--")+"\n                  ")])])])]),_vm._v(" "),_c('div',{staticClass:"item_bottom"},[_c('div',{staticClass:"item_bottom_price"},[_vm._v("\n                ￥"),_c('span',[_vm._v(_vm._s(_vm.priceInteger(item)))]),_vm._v(_vm._s(_vm.priceMinority(item))+"\n              ")]),_vm._v(" "),_c('div',{staticClass:"item_bottom_view_num"},[_c('img',{staticClass:"view_num_icon",attrs:{"src":`https://oss1.hk1686.com/img/036/zypa/2024/07/31/1722393808356`}}),_vm._v(" "),_c('span',{staticClass:"view_num_text"},[_vm._v(_vm._s(item.viewNum))])])])])}):_vm._e()],2),_vm._v(" "),(_vm.goodsListData.goodsList.length > 0)?_c('div',{staticClass:"turnPage",on:{"click":function($event){return _vm.handleCheckFilter()}}},[_c('span',[_vm._v("点击前往商品列表查看更多")]),_vm._v(" "),_c('img',{staticStyle:{"width":"14px","height":"14px"},attrs:{"src":`https://oss1.hk1686.com/img/036/zypa/2024/08/03/1722681196585`}})]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }